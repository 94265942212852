import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { Customer, NewCustomer } from '@dougs/customers/dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerHttpService {
  constructor(private readonly http: HttpClient) {}

  getCustomers(companyId: number): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customers?limit=1000&offset=0`,
    );
  }

  updateCustomer(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${customer.companyId}/customers/${customer.id}`,
      customer,
    );
  }

  addCustomer(companyId: number, customer: NewCustomer): Observable<Customer> {
    return this.http.post<Customer>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customers`,
      customer,
    );
  }

  deleteCustomer(customer: Customer): Observable<Customer> {
    return this.http.delete<Customer>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${customer.companyId}/customers/${customer.id}`,
    );
  }
}
