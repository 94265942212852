import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Customer, NewCustomer } from '@dougs/customers/dto';
import { CustomerHttpService } from '../http/customer.http';

interface CustomerState {
  customers: Customer[];
  newCustomer: NewCustomer | null;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerStateService extends StateService<CustomerState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly customerHttpService: CustomerHttpService,
  ) {
    super();
  }

  readonly customers$: Observable<Customer[]> = this.select((state) => state.customers);
  readonly newCustomer$: Observable<NewCustomer | null> = this.select((state) => state.newCustomer);

  async refreshCustomers(companyId: number): Promise<void> {
    try {
      this.setState({
        customers: await lastValueFrom(this.customerHttpService.getCustomers(companyId)),
      });
    } catch (e) {
      this.setState({
        customers: [],
      });
      this.logger.error(e);
    }
  }

  async updateCustomer(customer: Customer): Promise<void> {
    try {
      const customerUpdated: Customer = await lastValueFrom(this.customerHttpService.updateCustomer(customer));
      this.setState({
        customers: this.state.customers.map((customerIterated) =>
          customer.id === customerIterated.id ? customerUpdated : customerIterated,
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addCustomer(companyId: number, customer: NewCustomer): Promise<Customer | null> {
    try {
      const customerAdded: Customer = await lastValueFrom(this.customerHttpService.addCustomer(companyId, customer));
      this.setState({
        customers: [...(this.state?.customers || []), customerAdded],
        newCustomer: null,
      });

      return customerAdded;
    } catch (e) {
      this.logger.error(e);

      return null;
    }
  }

  async deleteCustomer(customer: Customer): Promise<void> {
    try {
      await lastValueFrom(this.customerHttpService.deleteCustomer(customer));

      this.setState({
        customers: this.state.customers.filter((customerIterated) => customer.id !== customerIterated.id),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  selectNewCustomer(customer: NewCustomer | null): void {
    this.setState({
      newCustomer: customer,
    });
  }
}
